import React from 'react';

import { Box } from '@mui/material';
function PdfIcon() {
    return (
        <Box>
            <img
                alt='file-extension'
                src='https://cdn4.iconfinder.com/data/icons/file-extensions-1/64/pdfs-512.png'
                style={{ width: 16 }}
            />
        </Box>
    );
}

export default PdfIcon;
