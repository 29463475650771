import {
    Box,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Chip,
    Stack,
} from '@mui/material';

import React, { useState } from 'react';
import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined';
import AdvancedSearchDialog from '../../components/AdvancedSearchDialog';
import StarredMail from '../Starred/StarredMail';
import AppTablePagination from '../../components/AppTablePagination';
import { styled } from '@mui/system';
import AppCreateButton from '../../components/AppCreateButton';

const CustomFormControl = styled(FormControl)(({ theme }) => ({
    backgroundColor: 'background.default',
    border: 'none',
}));
const Index = () => {
    const [time, setTime] = useState('');
    const [form, setForm] = useState('');
    const [to, setTo] = useState('');
    const [attachment, setAttachment] = useState('');

    const handleChangeTime = event => {
        setTime(event.target.value);
    };

    const handleChangeForm = event => {
        setForm(event.target.value);
    };

    const handleChangeTo = event => {
        setTo(event.target.value);
    };
    const handleChangeAttachment = event => {
        setAttachment(event.target.value);
    };

    return (
        <Box
            my={1}
            sx={{
                position: 'relative',
                minHeight: '100vh',
                backgroundColor: 'background.default',
                padding: '.5rem',
            }}>
            <Grid
                container
                display='flex'
                alignItems='center'
                justifyContent={'space-between'}>
                <Grid item>
                    <Grid
                        container
                        spacing={2}
                        display='flex'
                        alignItems={'center'}>
                        <Grid item>
                            <Typography
                                variant='h6'
                                sx={{
                                    backgroundColor: 'background.main',
                                    pl: 2,
                                    pr: 2,
                                    borderRadius: 3,
                                }}>
                                Select
                            </Typography>
                        </Grid>
                        <Grid item sx={{ minWidth: 90 }}>
                            <CustomFormControl fullWidth size='small'>
                                <InputLabel
                                    id='demo-simple-select-label'
                                    sx={{
                                        color: 'text.secondary',
                                        fontSize: '13px',
                                    }}>
                                    From
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={form}
                                    label='Form'
                                    onChange={handleChangeForm}
                                    size='small'>
                                    <MenuItem value='Gaurav'>Gaurav</MenuItem>
                                    <MenuItem value='Areeb'>Areeb</MenuItem>
                                    <MenuItem value='Ubaid'>Ubaid</MenuItem>
                                </Select>
                            </CustomFormControl>
                        </Grid>

                        <Grid item sx={{ minWidth: 90 }}>
                            <CustomFormControl fullWidth size='small'>
                                <InputLabel
                                    id='demo-simple-select-label'
                                    sx={{
                                        color: 'text.secondary',
                                        fontSize: '13px',
                                    }}>
                                    Time
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={time}
                                    label='Time'
                                    onChange={handleChangeTime}>
                                    <MenuItem value='Gaurav'>Gaurav</MenuItem>
                                    <MenuItem value='Areeb'>Areeb</MenuItem>
                                    <MenuItem value='Ubaid'>Ubaid</MenuItem>
                                </Select>
                            </CustomFormControl>
                        </Grid>

                        <Grid item sx={{ minWidth: 155 }}>
                            <CustomFormControl fullWidth size='small'>
                                <InputLabel
                                    id='demo-simple-select-label'
                                    sx={{
                                        color: 'text.secondary',
                                        fontSize: '13px',
                                    }}>
                                    Has attachment
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={attachment}
                                    label='Has attachment'
                                    onChange={handleChangeAttachment}>
                                    <MenuItem value='Gaurav'>Gaurav</MenuItem>
                                    <MenuItem value='Areeb'>Areeb</MenuItem>
                                    <MenuItem value='Ubaid'>Ubaid</MenuItem>
                                </Select>
                            </CustomFormControl>
                        </Grid>
                        <Grid item sx={{ minWidth: 75 }}>
                            <CustomFormControl fullWidth size='small'>
                                <InputLabel
                                    id='demo-simple-select-label'
                                    sx={{
                                        color: 'text.secondary',
                                        fontSize: '13px',
                                    }}>
                                    To
                                </InputLabel>
                                <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={to}
                                    label='To'
                                    onChange={handleChangeTo}>
                                    <MenuItem value='Gaurav'>Gaurav</MenuItem>
                                    <MenuItem value='Areeb'>Areeb</MenuItem>
                                    <MenuItem value='Ubaid'>Ubaid</MenuItem>
                                </Select>
                            </CustomFormControl>
                        </Grid>
                        <Grid item>
                            <AdvancedSearchDialog />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid style={{ paddingRight: 10 }}>
                    <Stack>
                        <Chip
                            label='Clickkle ads'
                            onClick={() => console.log('clikkle adds')}
                            sx={{ fontSize: '8px', height: '18px' }}
                        />
                        <Typography
                            variant='body1'
                            sx={{
                                backgroundColor: 'background.main',
                                borderRadius: 2,
                                width: '35px',
                                height: '35px',
                                padding: 0.7,
                            }}>
                            <LocalPostOfficeOutlinedIcon />
                        </Typography>
                    </Stack>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <AppTablePagination
                    rowsPerPageOptions={[]}
                    count={1}
                    page={0}
                />
            </Grid>
            <Box
                sx={{
                    textAlign: 'center',
                    my: 2,
                    p: 2,
                    borderRadius: '10px',
                }}
                bgcolor='background.main'>
                <Typography>
                    Messages that have been in the Bin for More than 30 days
                    will be delete automatically.{' '}
                    <a href='/' style={{ color: 'skyblue' }}>
                        Empty Bin now
                    </a>
                </Typography>
            </Box>
            <StarredMail
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
               odio eveniet cupiditate!.'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <StarredMail
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
               odio eveniet cupiditate!.'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <StarredMail
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
               odio eveniet cupiditate!.'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <StarredMail
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
               odio eveniet cupiditate!.'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <AppCreateButton />
        </Box>
    );
};

export default Index;
