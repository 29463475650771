import React from 'react';
import { Box, Grid, IconButton, Pagination } from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DeleteIcon from '@mui/icons-material/Delete';
import ReportIcon from '@mui/icons-material/Report';
import ArchiveIcon from '@mui/icons-material/Archive';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import MarkAsUnreadIcon from '@mui/icons-material/MarkAsUnread';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link } from 'react-router-dom';

const MailDetailHeader = () => {
    return (
        <>
            <Box my={5}>
                <Grid container spacing={4} display='flex' alignItems='center'>
                    <Grid item lg={8} xs={12}>
                        <Link to="http://localhost:3000/">
                        <IconButton sx={{ background: 'none', mx: 2 }} size='small'>
                        <KeyboardBackspaceIcon />
                        </IconButton>
                        </Link>
                        <IconButton sx={{ background: 'none' }} size='small'>
                            <ReportIcon />
                        </IconButton>
                        <IconButton
                            sx={{ background: 'none', mx: 1 }}
                            size='small'
                        >
                            <ArchiveIcon />
                        </IconButton>
                        <IconButton sx={{ background: 'none' }} size='small'>
                            <DeleteIcon />
                        </IconButton>
                        <IconButton
                            sx={{ background: 'none', mx: 1 }}
                            size='small'
                        >
                            <AccessTimeFilledIcon />
                        </IconButton>
                        <IconButton sx={{ background: 'none' }} size='small'>
                            <MarkAsUnreadIcon />
                        </IconButton>
                        <IconButton
                            sx={{ background: 'none', mx: 1 }}
                            size='small'
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Grid>
                    <Grid item lg={4} xs={12}>
                        <Pagination count={5} />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default MailDetailHeader;
