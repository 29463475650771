import React, { useState } from 'react';
import { Box, Divider, Fab, Modal, TextField, Typography } from '@mui/material';
import MailList from './MailList';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import MinimizeIcon from '@mui/icons-material/Minimize';
import DeleteIcon from '@mui/icons-material/Delete';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './quill.css';

const Index = () => {
    const [open, setOpen] = useState(false);
    const [maximize, setMaximize] = useState({
        top: '59%',
        left: '75%',
        width: 500,
    });
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' },
            ],
            ['link', 'image'],
            ['clean'],
        ],
    };

    const formats = [
        'header',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
    ];
    return (
        <Box my={5}>
            <Typography sx={{ mb: 2 }}>Search By</Typography>
            <MailList
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
                odio eveniet cupiditate!'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <MailList
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
                odio eveniet cupiditate! .'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <MailList
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
                odio eveniet cupiditate!'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <MailList
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
                            odio eveniet cupiditate!'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <MailList
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
                odio eveniet cupiditate!'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <MailList
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
                odio eveniet cupiditate!'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <MailList
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
                odio eveniet cupiditate! .'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <MailList
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
                odio eveniet cupiditate!'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <MailList
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
                            odio eveniet cupiditate!'
                time='May 12, 2023'
                subject='Garbage Message'
            />
            <MailList
                title='Gaurav gupta'
                content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
                odio eveniet cupiditate!'
                time='May 12, 2023'
                subject='Garbage Message'
            />

            <span style={{}}>
                <Fab
                    onClick={handleClickOpen}
                    color='primary'
                    aria-label='edit'
                    sx={{
                        margin: 0,
                        top: 'auto',
                        right: 90,
                        bottom: 20,
                        left: 'auto',
                        position: 'fixed',
                        // bgcolor: 'custom.common',
                        // color: 'background.default',
                        zIndex: 1,
                        display: { lg: 'flex', xs: 'none' },
                    }}
                >
                    <EditIcon />
                </Fab>
            </span>
            <Modal
                open={open}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                hideBackdrop={true}
                disableAutoFocus={true}
                sx={{ zIndex: 1 }}
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '59%',
                        left: '75%',
                        transform: 'translate(-50%, -50%)',
                        width: maximize,
                        bgcolor: 'background.paper',
                        boxShadow: 'rgba(0, 0, 0, 0.45) 0px 25px 20px -20px',
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        overflowX: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            px: 2,
                            py: 1,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography>New Message</Typography>
                        <Box display='flex' alignItems='end'>
                            <IconButton
                                onClick={() =>
                                    setMaximize({
                                        top: '59%',
                                        left: '75%',
                                        width: 500,
                                    })
                                }
                                sx={{
                                    background: 'none',
                                }}
                            >
                                <MinimizeIcon fontSize='small' />
                            </IconButton>
                            <IconButton
                                onClick={() =>
                                    setMaximize({
                                        top: '60%',
                                        left: '50%',
                                        width: 1100,
                                    })
                                }
                                sx={{
                                    background: 'none',
                                }}
                            >
                                <FullscreenIcon fontSize='small' />
                            </IconButton>
                            <IconButton
                                aria-label='close'
                                onClick={handleClose}
                                sx={{
                                    background: 'none',
                                }}
                            >
                                <CloseIcon fontSize='small' />
                            </IconButton>
                        </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ px: 2, pt: 2 }}>
                        <TextField
                            id='standard-password-input'
                            placeholder='To'
                            type='text'
                            variant='standard'
                            fullWidth
                        />
                        <TextField
                            id='standard-password-input'
                            placeholder='Subject'
                            type='text'
                            variant='standard'
                            fullWidth
                        />

                        <ReactQuill
                            // value={text}
                            modules={modules}
                            formats={formats}
                            // onChange={handleChange}
                            className='.richtextWrap'
                        />
                    </Box>
                    <Box
                        sx={{
                            px: 2,
                            py: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box display='flex' alignItems='end'>
                            <IconButton
                                sx={{
                                    background: 'none',
                                }}
                            >
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                            <IconButton
                                sx={{
                                    background: 'none',
                                }}
                            >
                                <EditIcon fontSize='small' />
                            </IconButton>
                        </Box>
                        <Button
                            variant='contained'
                            size='small'
                            sx={{
                                background: '#007FFF',
                                '&:hover': {
                                    background: '#007FFF',
                                },
                            }}
                        >
                            Send
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default Index;
