import InboxIcon from '@mui/icons-material/Inbox';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SendIcon from '@mui/icons-material/Send';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import SmsFailedIcon from '@mui/icons-material/SmsFailed';
import DeleteIcon from '@mui/icons-material/Delete';
import { env } from '../utilities/function';

const More = [
    {
        name: 'Bin',
        icon: <DeleteIcon fontSize='small' />,
        to: '/bin',
    },
    {
        name: 'Span',
        icon: <SmsFailedIcon fontSize='small' />,
        to: '/span',
    },
];
const navLinks = [
    {
        name: 'Indox',
        icon: <InboxIcon fontSize='small' />,
        to: '/',
    },
    {
        name: 'Starred',
        icon: <StarOutlineIcon fontSize='small' />,
        to: '/starred',
    },
    {
        name: 'Snoozed',
        icon: <AccessTimeIcon fontSize='small' />,
        to: '/snoozed',
    },
    {
        name: 'Sent',
        icon: <SendIcon fontSize='small' />,
        to: '/sent',
    },
    {
        name: 'Drafts',
        icon: <InsertDriveFileIcon fontSize='small' />,
        to: '/draft',
    },
];
const Apps = [
    {
        name: 'Ads',
        headerName: 'Ads',
        icon: env('ADS_LOGO_PLAIN'),
        to: '/ads',
    },
    {
        name: 'Campaigns',
        headerName: 'Campaigns',
        icon: env('CAMPAIGNS_LOGO_PLAIN'),
        to: '/campaigns',
    },
    {
        name: 'E-Sign',
        headerName: 'E-Sign',
        icon: env('ESIGN_LOGO_PLAIN'),
        to: '/e-sign',
    },
    {
        name: 'Files',
        headerName: 'Files',
        icon: env('FILES_LOGO_PLAIN'),
        to: '/files',
    },

    {
        name: 'Host',
        headerName: 'Host',
        icon: env('HOST_LOGO_PLAIN'),
        to: '/host',
    },
    {
        name: 'Pitch',
        headerName: 'Pitch',
        icon: env('PITCH_LOGO_PLAIN'),
        to: '/pitch',
    },
    {
        name: 'Projects',
        headerName: 'Projects',
        icon: env('PROJECT_LOGO_PLAIN'),
        to: '/projects',
    },
    {
        name: 'Launch',
        headerName: 'Launch',
        icon: env('LAUNCH_LOGO_PLAIN'),
        to: '/launch',
    },
];

export { navLinks, Apps, More };
