import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel,Grid ,Select,MenuItem,FormControl} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/system';

const UnderlineButton = styled(Button)(({ theme }) => ({
  textDecoration: 'underline',  
  textTransform: 'none', 
  '&:hover': {
      textDecoration: 'underline', 
      backgroundColor: 'transparent'
  },
  
}));
const AdvancedSearchDialog = () => {
    const [open, setOpen] = useState(false);
    const [selectedOptionSizeType, setSelectedOptionSizeType] = useState('grater than');  
    const [selectedOptionSize, setSelectedOptionSize] = useState('MB');  
    const [selectedOptionDate, setSelectedOptionDate] = useState('1 day');  

  const handleChangeSizeType = (event) => {
    setSelectedOptionSizeType(event.target.value);
  };
  const handleChangeSize = (event) => {
    setSelectedOptionSize(event.target.value);
  };
   const handleChangeDate = (event) => {
    setSelectedOptionDate(event.target.value);
   };
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
     
  return (
    <div>
      <UnderlineButton   onClick={handleOpen} sx={{color:'text.primary'}} >
        Advance search
      </UnderlineButton>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth='md'>
                <DialogContent>
                     <Grid container>
                           <Grid item xs={3}>
                           <label>From </label>
                           </Grid>
                           <Grid item xs={9}>
                           <TextField fullWidth variant='standard' size='small' />
                           </Grid>
                     </Grid>
                     <Grid container>
                           <Grid item xs={3}>
                           <label>To </label>
                           </Grid>
                           <Grid item xs={9}>
                           <TextField fullWidth variant='standard' size='small' />
                           </Grid>
                     </Grid>
                     <Grid container>
                           <Grid item xs={3}>
                           <label>Subject </label>
                           </Grid>
                           <Grid item xs={9}>
                           <TextField fullWidth variant='standard' size='small' />
                           </Grid>
                     </Grid>
                     <Grid container>
                           <Grid item xs={3}>
                           <label>Has the words </label>
                           </Grid>
                           <Grid item xs={9}>
                           <TextField fullWidth variant='standard' size='small' />
                           </Grid>
                     </Grid>
                     <Grid container>
                           <Grid item xs={3}>
                           <label>Doesn't have </label>
                           </Grid>
                           <Grid item xs={9}>
                           <TextField fullWidth variant='standard' size='small' />
                           </Grid>
                     </Grid>
                     
                     <Grid container>
                           <Grid item xs={3}>
                           <label>Size</label>
                           </Grid>
                           <Grid item xs={9}>
                           <Grid container  >
                                <Grid item xs={3}>
                                        <FormControl variant="standard" sx={{ minWidth: 160 }}>
                                                    <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={selectedOptionSizeType}
                                                    onChange={handleChangeSizeType}
                                                    >
                                                    <MenuItem value={'grater than'}  >greater than</MenuItem>
                                                    <MenuItem value={'less than'}>less than</MenuItem>
                                                    </Select>
                                            </FormControl>
                                </Grid>
                                <Grid item xs={4} marginX={5}>
                                <TextField fullWidth variant='standard' size='small' />
                                </Grid>
                                <Grid item xs={2}>
                                        <FormControl variant="standard" sx={{minWidth: 140 }}>
                                                    <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={selectedOptionSize}
                                                    onChange={handleChangeSize}
                                                    >
                                                    <MenuItem value={'MB'}>MB</MenuItem>
                                                    <MenuItem value={'KB'}>KB</MenuItem>
                                                    <MenuItem value={'Bytes'}>Bytes</MenuItem>
                                                    </Select>
                                            </FormControl>
                                </Grid>
                           </Grid>
                           </Grid>
                     </Grid>
                     <Grid container>
                           <Grid item xs={3}>
                           <label>Date within </label>
                           </Grid>
                           <Grid item xs={4.5}>
                                    <FormControl variant="standard" sx={{  minWidth: 250 }}>
                                                        <Select
                                                        labelId="demo-simple-select-standard-label"
                                                        id="demo-simple-select-standard"
                                                        value={selectedOptionDate}
                                                        onChange={handleChangeDate}
                                                        >
                                                        <MenuItem value={'1 day'}>1 day</MenuItem>
                                                        <MenuItem value={'3 days'}>3 days</MenuItem>
                                                        <MenuItem value={'1 week'}>1 week</MenuItem>
                                                        <MenuItem value={'2 weeks'}>2 weeks</MenuItem>
                                                        <MenuItem value={'1 month'}>1 month</MenuItem>
                                                        <MenuItem value={'2 months'}>2 months</MenuItem>
                                                        <MenuItem value={'6 months'}>6 months</MenuItem>
                                                        <MenuItem value={'1 year'}>1 year</MenuItem>
                                                        </Select>
                                    </FormControl>
                           </Grid>  <Grid item xs={4.5}>
                           <TextField type='date' fullWidth variant='standard' size='small'   />
                           </Grid>
                     </Grid>
                     <Grid container>
                           <Grid item xs={3}>
                           <label>Search </label>
                           </Grid>
                           <Grid item xs={9}>
                           <TextField fullWidth variant='standard' size='small' />
                           </Grid>
                     </Grid>
                     
                     <Grid container>
                           <Grid item xs={6}>
                           <FormControlLabel control={<Checkbox />} label='Has attachment' />
                           </Grid>
                           <Grid item xs={6}>
                           <FormControlLabel control={<Checkbox />} label="Don't include chats" />
                           </Grid>
                     </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant='text'>
                        Create filter
                    </Button>
                    <Button onClick={handleClose} color='primary' variant='contained'>
                        Search
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AdvancedSearchDialog;
