import {
    Avatar,
    Box,
    Button,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';

const Generalsetting = () => {
    return (
        <>
            <Grid container spacing={2} display='flex' alignItems='center'>
                <Grid item lg={3} xs={12}>
                    <Typography>My picture :</Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <Avatar>G</Avatar>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography>Language :</Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <FormControl fullWidth size='small'>
                        <Select
                            disabled
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            //     value={employ.jobType}
                            //     onChange={handleChange}
                            //     name='jobType'
                            defaultValue='English (UK)'
                        >
                            <MenuItem value={'English (UK)'}>
                                English (UK)
                            </MenuItem>
                            <MenuItem value={'Other'}>Other</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography>Phone Number :</Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <TextField
                        placeholder='+91 6392212199'
                        fullWidth
                        size='small'
                    />
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography>Maximun page show :</Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <Typography>show 50 conversation per page</Typography>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography>Text Style :</Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <Typography>Sanse serif</Typography>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography>Desktop notification : </Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <RadioGroup
                        aria-labelledby='demo-radio-buttons-group-label'
                        defaultValue='female'
                        name='radio-buttons-group'
                    >
                        <FormControlLabel
                            value='female'
                            control={<Radio />}
                            label='New mail notification on'
                        />
                        <FormControlLabel
                            value='male'
                            control={<Radio />}
                            label='important mail notification on'
                        />
                        <FormControlLabel
                            value='other'
                            control={<Radio />}
                            label='mail notification off'
                        />
                    </RadioGroup>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography>Conversation View : </Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <RadioGroup
                        aria-labelledby='demo-radio-buttons-group-label'
                        defaultValue='female'
                        name='radio-buttons-group'
                    >
                        <FormControlLabel
                            value='female'
                            control={<Radio />}
                            label='converstion off'
                        />
                        <FormControlLabel
                            value='male'
                            control={<Radio />}
                            label='conversation on'
                        />
                    </RadioGroup>
                </Grid>
                <Grid item lg={3} xs={12}>
                    <Typography>Auto correct: </Typography>
                </Grid>
                <Grid item lg={9} xs={12}>
                    <RadioGroup
                        aria-labelledby='demo-radio-buttons-group-label'
                        defaultValue='female'
                        name='radio-buttons-group'
                    >
                        <FormControlLabel
                            value='female'
                            control={<Radio />}
                            label='Auto correction off'
                        />
                        <FormControlLabel
                            value='male'
                            control={<Radio />}
                            label='Auto correction  on'
                        />
                    </RadioGroup>
                </Grid>
                <Box my={6} textAlign='right'>
                    <Button
                        type='submit'
                        // disabled={Boolean(loader)}
                        // endIcon={loader}
                        variant='contained'
                        sx={{
                            mx: 2,

                            background: '#4674FF',
                            '$:hover': {
                                background: '#4674FF',
                            },
                        }}
                    >
                        Save Changes
                    </Button>

                    <Button
                        variant='contained'
                        sx={{
                            background: '#F91A3F',
                            '$:hover': {
                                background: '#F91A3F',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                </Box>
            </Grid>
        </>
    );
};

export default Generalsetting;
