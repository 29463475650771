import {
    Box,
    Grid,
    Typography,
    Chip,
}

from '@mui/material';
import React  from 'react';
import LocalPostOfficeOutlinedIcon from '@mui/icons-material/LocalPostOfficeOutlined';
import AppTablePagination from '../../components/AppTablePagination';
import SentMail from './SentMail'; 
import AppCreateButton from '../../components/AppCreateButton';
   
const Index = () => {
    
    return (
         
        <Box my={.5} sx={{ position: 'relative', minHeight: '100vh',backgroundColor:'background.default' ,padding:'3px'}}>
            <Grid container   display='flex' alignItems='center' justifyContent={'space-between'} sx={{ backgroundColor:'none'}} >
                <Grid item>
                <Grid container spacing={2} display='flex' alignItems={'center'}>
                <Grid item>
                    <Typography variant='h6' sx={{backgroundColor:'background.main',pl:2,pr:2,borderRadius:3}}>Select</Typography>
                </Grid>
                <Grid item>
                    <Typography variant='body1'  sx={{backgroundColor:'background.main',borderRadius:2,width:'35px',height:'35px',padding:.7}}>
                    <LocalPostOfficeOutlinedIcon   />
                    </Typography>
                </Grid> 
            </Grid>
            </Grid>
                <Grid  item  >
                   <Chip label='Clickkle ads' onClick={()=>console.log('clikkle adds')}  sx={{ fontSize:'8px',height:'18px',marginRight:0}}/>
                </Grid>
       </Grid>
       <Grid>
       <AppTablePagination
              rowsPerPageOptions={[]}  
              count={1} 
              page={0}
               
            />
        </Grid>  
        <Grid container>
        <SentMail
               to='Toni'   
               time='May 12, 2023'
               
            />               
        <SentMail
               to='Mark'
               time='May 12, 2023'
               subject='Garbage Message'
            />              
        <SentMail
               to='Toni'
               time='May 12, 2023'
               subject='Garbage Message'
            />
            <SentMail
               to='mark'
               time='May 12, 2023'
               
            />

        </Grid>
             <AppCreateButton/>
        </Box>
        
    );
};

export default Index;
