import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import React from 'react';
import MailList from '../Inbox/MailList';

const Index = () => {
    const [menu, setMenu] = React.useState('');

    const handleChange = (event) => {
        setMenu(event.target.value);
    };
    return (
        <>
            <Box my={5}>
                <Grid
                    container
                    spacing={2}
                    display='flex'
                    alignItems='center'
                    sx={{ mb: 5 }}
                >
                    <Grid item>
                        <Typography variant='h6'>Select</Typography>
                    </Grid>

                    <Grid item sx={{ minWidth: 120 }}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id='demo-simple-select-label'>
                                From
                            </InputLabel>
                            <Select
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={menu}
                                label='Age'
                                onChange={handleChange}
                            >
                                <MenuItem value='Gaurav'>Gaurav</MenuItem>
                                <MenuItem value='Areeb'>Areeb</MenuItem>
                                <MenuItem value='Ubaid'>Ubaid</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ minWidth: 120 }}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id='demo-simple-select-label'>
                                Time
                            </InputLabel>
                            <Select
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={menu}
                                label='Age'
                                onChange={handleChange}
                            >
                                <MenuItem value='Gaurav'>Gaurav</MenuItem>
                                <MenuItem value='Areeb'>Areeb</MenuItem>
                                <MenuItem value='Ubaid'>Ubaid</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item sx={{ minWidth: 120 }}>
                        <FormControl fullWidth size='small'>
                            <InputLabel id='demo-simple-select-label'>
                                To
                            </InputLabel>
                            <Select
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={menu}
                                label='Age'
                                onChange={handleChange}
                            >
                                <MenuItem value='Gaurav'>Gaurav</MenuItem>
                                <MenuItem value='Areeb'>Areeb</MenuItem>
                                <MenuItem value='Ubaid'>Ubaid</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <Button variant='contained'>Advanced Search</Button>
                    </Grid>
                </Grid>
                <MailList
                    title='Gaurav gupta'
                    content='Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nulla
                odio eveniet cupiditate!'
                    time='May 12, 2023'
                    subject='Garbage Message'
                />
            </Box>
        </>
    );
};

export default Index;
