import { Avatar, Box, Card, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import React from 'react';
import StarBorderPurple500OutlinedIcon from '@mui/icons-material/StarBorderPurple500Outlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkunreadIcon from './CheckBox';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { Link } from 'react-router-dom';
import './quill.css';

const MailList = props => {
    const { title, content, time, subject } = props;
    // const [showLeftPanel, setShowLeftPanel] = React.useState(true);
    // const [selectedMail, setSelectedMail] = React.useState(null);

    // const togglePanel = mail => {
    //     setSelectedMail(mail);
    // };

    // const closeMail = () => {
    //     setSelectedMail(null);
    // };
    return (
        <>
            <Grid container>
                <Grid
                    item
                    xs={12}
                    // onClick={togglePanel}
                >
                    <Link to='/mail-detail' style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Card
                            sx={{
                                p: { xs: 1, lg: 0 },
                                px: { xs: 1.5, lg: 1 },
                                my: 0.8,

                                borderRadius: '10px',

                                //   transition: "all 0.3s ease-in",
                                '&:hover': {
                                    // transform: 'scale(1)',
                                    backgroundColor: 'custom.cardHover',
                                    boxShadow:
                                        'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
                                    '#options': {
                                        opacity: 1,

                                        backgroundColor: 'custom.cardHover',
                                    },
                                },
                            }}
                            elevation={0}>
                            <Grid
                                container
                                display='flex'
                                sx={{
                                    flexDirection: { lg: 'row', xs: 'column' },
                                    alignItems: { lg: 'center', xs: 'start' },
                                    position: 'relative',
                                }}
                                spacing={{ xs: 0, lg: 4 }}>
                                <Grid item>
                                    <Box display='flex' alignItems='center'>
                                        <IconButton
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            sx={{
                                                background: 'none',
                                                display: {
                                                    md: 'block',
                                                    xs: 'none',
                                                },
                                            }}>
                                            <StarBorderPurple500OutlinedIcon />
                                        </IconButton>
                                        <Avatar
                                            alt='Remy Sharp'
                                            src='https://shorturl.at/fjqz9'
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                mt: 1,
                                                display: {
                                                    md: 'none',
                                                    xs: 'block',
                                                },
                                            }}
                                        />
                                        <Typography fontWeight='bold' sx={{ ml: 1.5 }}>
                                            {title}
                                        </Typography>
                                    </Box>
                                    <Box sx={{}}>
                                        <IconButton
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            sx={{
                                                position: 'absolute',
                                                right: 0,
                                                top: 0,
                                                background: 'none',
                                                display: {
                                                    md: 'none',
                                                    xs: 'block',
                                                },
                                            }}>
                                            {' '}
                                            <StarBorderPurple500OutlinedIcon />
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid item xs sx={{ pl: { md: 0, xs: 5 } }}>
                                    <Typography
                                        variant='body2'
                                        sx={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            maxWidth: {
                                                md: '600px',
                                                sm: '390px',
                                                xs: '210px',
                                            },
                                            textOverflow: 'ellipsis',
                                            ml: { md: 5, xs: 0 },
                                        }}
                                        color='text.secondary'>
                                        <span
                                            style={{
                                                color: 'grey',
                                                fontWeight: 'bold',
                                                paddingRight: '5px',
                                            }}>
                                            {subject}
                                        </span>
                                        {content}
                                    </Typography>
                                </Grid>
                                <Grid item sx={{ pl: { md: 0, xs: 5 } }}>
                                    <Typography color='text.secondary' variant='caption'>
                                        {time}
                                    </Typography>
                                </Grid>
                                <Box
                                    id='options'
                                    bgcolor='background.default'
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        bottom: 0,
                                        opacity: 0,
                                    }}>
                                    <Tooltip title='Archive'>
                                        <IconButton
                                            sx={{
                                                mr: 0,

                                                background: 'none',
                                                '&:hover': {
                                                    background: 'none',
                                                },
                                            }}>
                                            <ArchiveOutlinedIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Delete'>
                                        <IconButton
                                            sx={{
                                                mr: 0,

                                                background: 'none',
                                                '&:hover': {
                                                    background: 'none',
                                                },
                                            }}>
                                            <DeleteIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Mark as read'>
                                        <IconButton
                                            sx={{
                                                mr: 0,

                                                background: 'none',
                                                '&:hover': {
                                                    background: 'none',
                                                },
                                            }}>
                                            <MarkunreadIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Snooze'>
                                        <IconButton
                                            sx={{
                                                mr: 0,

                                                background: 'none',
                                                '&:hover': {
                                                    background: 'none',
                                                },
                                            }}>
                                            <AccessTimeIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
        </>
    );
};

export default MailList;
