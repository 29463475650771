import * as React from 'react';
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/system';

const CustomTablePagination = styled(TablePagination)(({ theme }) => {
    return {
        '& .MuiTablePagination-toolbar': {
            maxHeight: '10px',  
          },
          '& .MuiTablePagination-input': {
            fontSize: '10px', 
          },  
          '& .MuiTablePagination-spacer': {
            flex: 'none', 
          },
          '& .MuiTablePagination-actions': {
            marginLeft: '0px',  
          },
    };
  });
 

export default function AppTablePagination(props) {
   
    const handleChangePage = (event, newPage) => {
        // Handle page change
      };
    const { rowsPerPage = 1, ...otherProps } = props;
  return (
    <CustomTablePagination
    {...otherProps}
    rowsPerPage={rowsPerPage}
     
    labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
    size='small'
    onPageChange={()=>{handleChangePage()}}
     
    sx={{ borderBottom: 'none',}}
    
    />
  );
}