import React from 'react';
import  Box  from '@mui/material/Box';
import  Fab  from '@mui/material/Fab';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

const AppCreateButton = (props) =>{
    const fabStyles = {
        backgroundColor: '#FFFFFF',  
        color: '#000000',  
        fontWeight:'bold'
      };
    return (
        <Box sx={{
                        position: 'fixed',
                        bottom: '4rem',
                        right: '5rem',
                    }}
            >
                <Fab variant='extended' color='primary' style={fabStyles}>
                    <ModeEditOutlineIcon />
                    Create
                </Fab>
            </Box>
    );
}

export default AppCreateButton;