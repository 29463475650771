import { Grid, Typography,FormControl,FormLabel,FormControlLabel,RadioGroup  } from '@mui/material';
import React from 'react';
 
import { styled } from '@mui/material/styles';
import Radio from '@mui/material/Radio';
const CustomA = styled('a')({
    color:'skyblue'
})
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 15,
    height: 15,
    boxShadow:
      theme.palette.mode === 'dark'
        ? '0 0 0 1px rgb(16 22 26 / 40%)'
        : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#F7F9F6' : '#f5f8fa',
    backgroundImage:
      theme.palette.mode === 'dark'
        ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
        : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
      outline: '10px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: theme.palette.mode === 'dark' ? '#F7F9F6' : '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background:
        theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
  }));
  
  const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#fff',
    
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      borderRadius: '100%',
      display:'block',
      width: 15,
      height: 15,
      backgroundImage: 'radial-gradient(#212121 30%,#424242 35%,#616161 38%,#757575 40%,#9e9e9e 41%,#bdbdbd 42%,#e0e0e0 41.5%,#eeeeee 42%,#f5f5f5 42.3%,#fafafa 42.5%,transparent 32%)',
      content: '""',
       
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
    },
  });
  
   
  function BpRadio(props) {
    return (
      <Radio
        disableRipple
        color="default"
        checkedIcon={<BpCheckedIcon />}
        icon={<BpIcon />}
        {...props}
      />
    );
  }
const ImportExportsetting = () => {
    return(
        <>
        <Grid container   display='flex' alignItems='center'>
            <Grid item xs={12}>
                <Grid container spacing={0} marginBottom={'1rem'}>
                    <Grid item md={3.5} xs={12}>
                        <Typography variant='body1' component={'subtitle1'}>  Change account settings:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8.5} sx={{ pl: { md: 0, xs: 5 }}}>
                          <Typography><CustomA href='#' >Change password</CustomA></Typography>
                          <Typography><CustomA href='#'>Change password recovery options</CustomA></Typography>
                          <Typography><CustomA href='#'>Other Google Account settings</CustomA></Typography>
                    </Grid> 
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container   marginBottom={'1rem'} >
                    <Grid item md={3.5} xs={12}>
                        <Typography variant='body1' component={'subtitle1'}>Using Gmail for work?</Typography>
                    </Grid>
                    <Grid item md={8.5} xs={12} sx={{ pl: { md: 0, xs: 5 }}}>
                        <Typography variant='subtitle2' component={'body2'}>
                            Businesses get yourname@example.com email,more storage and admin tools with Google Workspace.
                            <CustomA href='#'>Learn more</CustomA>
                        </Typography>
                    </Grid> 
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={0} marginBottom={'1rem'}>
                    <Grid item xs={12} md={3.5}>
                        <Typography variant='body1' component={'subtitle1'}>Import mail and contacts:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8.5} sx={{ pl: { md: 0, xs: 5 }}}>
                        <Typography variant='body2' component={'body2'}>
                            Import from Yahoo!,Hotmail,AOL or other webmail or POP3 acounts.
                        </Typography>
                        <Typography><CustomA href='#'>Import mail contacts</CustomA></Typography>
                    </Grid> 
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={0} marginBottom={'1rem'}>
                    <Grid item xs={12} md={3.5}>
                        <Typography variant='body1' component={'subtitle1'}> Send mail as:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8.5} sx={{ pl: { md: 0, xs: 5 }}}>  
                        <Typography variant='body2' component={'body2'}>M&uacute;ntaky RoHan {'<'}mysterious.mind443@gmail.com{'>'}    <span style={{marginLeft:'5rem'}}><CustomA href='#'> edit info</CustomA> </span></Typography>
                        <Typography><CustomA href='#'>Add another email address</CustomA></Typography>
                    </Grid> 
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={0} marginBottom={'1rem'}>
                    <Grid item xs={12} md={3.5}>
                        <Typography variant='body1' component={'subtitle1'}>Grant access to your account:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8.5} sx={{ pl: { md: 0, xs: 5 }}}>
                         <Grid item marginBottom={'1rem'}>
                         <FormControl>
                             <FormLabel id="customized-radios" sx={{color:'text.primary'}}>Mark as read</FormLabel>
                                <RadioGroup
                                    defaultValue="1"
                                    aria-labelledby="customized-radios"
                                    name="customized-radios"
                                >
                                    <FormControlLabel 
                                        value="1" 
                                        control={<BpRadio />} 
                                        label="Mark conversation as read when opened by others" 
                                        />
                                    <FormControlLabel 
                                        value="2" 
                                        control={<BpRadio />} 
                                        label="Leave conversation as read when opened by others" />
                                     
                                   
                                </RadioGroup>
                         </FormControl>
                         </Grid>
                         <Grid item>
                         <FormControl>
                             <FormLabel id="customized-radios" sx={{color:'text.primary'}}>Sender information</FormLabel>
                                <RadioGroup
                                    defaultValue="1"
                                    aria-labelledby="customized-radios"
                                    name="customized-radios"
                                >
                                    <FormControlLabel 
                                        value="1" 
                                        control={<BpRadio />} 
                                        label="Show this address and the person who sent it ('sent by...')" 
                                        />
                                    <FormControlLabel 
                                        value="2" 
                                        control={<BpRadio />} 
                                        label="Show this address only (mysterious.mind443@gmail.com) " />
                                     
                                   
                                </RadioGroup>
                         </FormControl>
                         </Grid>
                    </Grid> 
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container  spacing={0} marginBottom={'2rem'}>
                    <Grid item xs={12} md={3.5}>
                        <Typography variant='body1' component={'body1'}>Add additional storage:</Typography>
                    </Grid>
                    <Grid item xs={12} md={8.5} sx={{ pl: { md: 0, xs: 5 }}}>  
                        <Typography variant='body2'>Yor are currently using 4.7 GB (31%) of  your 15 GB</Typography>
                        <Typography variant='body2'>Need more space? <CustomA href='#'>Purchase additional storage</CustomA></Typography>
                    </Grid> 
                </Grid>
            </Grid>
            
        </Grid>
        </>
    )
};

export default ImportExportsetting;
