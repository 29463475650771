import {
    Avatar,
    Box,
    Card,
    Grid,
    IconButton,
    Tooltip,
    Typography,
    Chip,
} from '@mui/material';
import React from 'react';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import { Link } from 'react-router-dom';
import PdfIcon from '../../components/PdfIcon';
import AppStarIcon from '../../components/AppStarIcon';

const DraftMail = props => {
    const chipStyles = {
        borderColor: '#00b0ff',
        borderWidth: '1px',
        borderStyle: 'solid',
        paddingLeft: 6,
        paddingTop: 2,
    };
    const { time, subject, isTrue = false } = props;
    return (
        <>
            <Grid container>
                <Grid item xs={12}>
                    <Link
                        to='/mail-detail'
                        style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Card
                            sx={{
                                p: { xs: 1, lg: 0 },
                                px: { xs: 1.5, lg: 1 },
                                my: 0.5,
                                backgroundColor: 'background.box',
                                borderRadius: '10px',
                                //   transition: "all 0.3s ease-in",
                                '&:hover': {
                                    // transform: 'scale(1)',
                                    backgroundColor: 'custom.cardHover',
                                    boxShadow:
                                        'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
                                    '#options': {
                                        opacity: 1,

                                        backgroundColor: 'custom.cardHover',
                                    },
                                },
                            }}
                            elevation={0}>
                            <Grid
                                container
                                display='flex'
                                sx={{
                                    flexDirection: {
                                        md: 'row',
                                        lg: 'row',
                                        xl: 'row',
                                        xs: 'column',
                                    },
                                    alignItems: {
                                        md: 'center',
                                        lg: 'center',
                                        xl: 'center',
                                        xs: 'start',
                                    },
                                    position: 'relative',
                                }}
                                justifyContent={'space-between'}
                                spacing={{ xs: 0, lg: 1, md: 0.5 }}>
                                <Grid item md={2}>
                                    <Box
                                        display='flex'
                                        alignItems='center'
                                        justifyContent={'space-between'}>
                                        <IconButton
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            sx={{
                                                background: 'none',
                                                display: {
                                                    md: 'block',
                                                    xs: 'none',
                                                },
                                            }}>
                                            <AppStarIcon isTrue={isTrue} />
                                        </IconButton>
                                        <Avatar
                                            alt='Remy Sharp'
                                            src='https://shorturl.at/fjqz9'
                                            sx={{
                                                width: 30,
                                                height: 30,
                                                mt: 1,
                                                display: {
                                                    md: 'none',
                                                    xs: 'block',
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                ml: 1.5,
                                                color: 'red',
                                                marginRight: 20,
                                            }}>
                                            Draft
                                        </Typography>
                                    </Box>
                                    <Box sx={{}}>
                                        <IconButton
                                            disableFocusRipple
                                            disableRipple
                                            disableTouchRipple
                                            sx={{
                                                position: 'absolute',
                                                right: 0,
                                                top: 0,
                                                background: 'none',
                                                display: {
                                                    md: 'none',
                                                    xs: 'block',
                                                },
                                            }}>
                                            {' '}
                                            <AppStarIcon isTrue={isTrue} />
                                        </IconButton>
                                    </Box>
                                </Grid>
                                <Grid item xs={8} sx={{ pl: { md: 0, xs: 5 } }}>
                                    <Grid
                                        container
                                        sx={{ placeItems: 'center' }}
                                        spacing={{ xs: 2 }}>
                                        <Grid item md={3}>
                                            <Typography
                                                variant='body2'
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    maxWidth: {
                                                        md: '500px',
                                                        sm: '390px',
                                                        xs: '210px',
                                                    },
                                                    textOverflow: 'ellipsis',
                                                    ml: { md: 0, xs: 0 },
                                                    '&:hover': {
                                                        overflow: 'hidden',
                                                    },
                                                }}
                                                color='text.secondary'>
                                                {subject
                                                    ? subject
                                                    : ' (No subject)'}
                                            </Typography>
                                        </Grid>
                                        <Grid item md={3}>
                                            <Chip
                                                label='untitled-1.pdf'
                                                avatar={<PdfIcon />}
                                                style={chipStyles}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item sx={{ pl: { md: 0, xs: 5 } }}>
                                    <Typography
                                        color='text.secondary'
                                        variant='caption'>
                                        {time}
                                    </Typography>
                                </Grid>
                                <Box
                                    id='options'
                                    bgcolor='background.default'
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        bottom: 0,
                                        opacity: 0,
                                    }}>
                                    <Tooltip title='Archive'>
                                        <IconButton
                                            sx={{
                                                mr: 0,

                                                background: 'none',
                                                '&:hover': {
                                                    background: 'none',
                                                },
                                            }}>
                                            <ArchiveIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Delete'>
                                        <IconButton
                                            sx={{
                                                mr: 0,

                                                background: 'none',
                                                '&:hover': {
                                                    background: 'none',
                                                },
                                            }}>
                                            <DeleteIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Mark as read'>
                                        <IconButton
                                            sx={{
                                                mr: 0,

                                                background: 'none',
                                                '&:hover': {
                                                    background: 'none',
                                                },
                                            }}>
                                            <MarkEmailUnreadOutlinedIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title='Snooze'>
                                        <IconButton
                                            sx={{
                                                mr: 0,

                                                background: 'none',
                                                '&:hover': {
                                                    background: 'none',
                                                },
                                            }}>
                                            <NotificationsPausedIcon fontSize='small' />
                                        </IconButton>
                                    </Tooltip>
                                </Box>
                            </Grid>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
        </>
    );
};

export default DraftMail;
