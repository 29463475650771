import { Route, Routes } from 'react-router-dom';
import './App.css';
import Header from './components/Header';
import Inbox from './pages/Inbox';
import Starred from './pages/Starred';
import Snoozed from './pages/Snoozed';
import Sent from './pages/Sent';
import Draft from './pages/Draft';
import Bin from './pages/Bin';
import Span from './pages/Span';
import Setting from './pages/Setting';
import MailDetails from './pages/MailDetails';

function App() {
    return (
        <>
            <Header>
                <Routes>
                    <Route path='/' element={<Inbox />} />
                    <Route path='/mail-detail' element={<MailDetails />} />
                    <Route path='/starred' element={<Starred />} />
                    <Route path='/snoozed' element={<Snoozed />} />
                    <Route path='/sent' element={<Sent />} />
                    <Route path='/draft' element={<Draft />} />
                    <Route path='/span' element={<Span />} />
                    <Route path='/bin' element={<Bin />} />
                    <Route path='/setting' element={<Setting />} />
                </Routes>
            </Header>
        </>
    );
}

export default App;
