import React from 'react';
import MailDetailHeader from '../components/MailDetailHeader';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    Grid,
    IconButton,
    Typography,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplyIcon from '@mui/icons-material/Reply';
import StarOutlineIcon from '@mui/icons-material/StarOutline';

const MailDetails = () => {
    return (
        <>
            <MailDetailHeader />
            <Box mt={4}>
                <Card>
                    <CardContent>
                        <Typography variant='h6'>
                            Cash and Cash Equivalents for the week ending
                            30/09/2023 with Angel One Limited
                        </Typography>
                        <Grid container spacing={2} my={4}>
                            <Grid item xs>
                                <Box display='flex' alignItems='center'>
                                    <Avatar>A</Avatar>
                                    <Typography sx={{ px: 1 }}>
                                        admin@clikkle.com
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <IconButton
                                    sx={{ background: 'none', mx: 1 }}
                                    size='small'
                                >
                                    <StarOutlineIcon />
                                </IconButton>
                                <IconButton
                                    sx={{ background: 'none' }}
                                    size='small'
                                >
                                    <ReplyIcon />
                                </IconButton>
                                <IconButton
                                    sx={{ background: 'none', mx: 1 }}
                                    size='small'
                                >
                                    <MoreVertIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Card>
                            <CardContent>
                                <Typography>
                                    Lorem ipsum dolor sit amet consectetur
                                    adipisicing elit. Vero repudiandae laborum
                                    voluptates nesciunt est fuga laboriosam
                                    labore sunt officiis magnam aspernatur,
                                    earum totam, voluptatibus aut necessitatibus
                                    commodi tempora omnis, recusandae ut
                                    officia. Quos ea neque omnis culpa
                                    blanditiis animi dolore quae? Laboriosam
                                    quaerat unde voluptate. At aut quod,
                                    deserunt voluptates saepe repellat nobis
                                    vero fuga tenetur, ad, culpa voluptatibus
                                    inventore distinctio labore minus vitae eos.
                                    Facere tempora omnis voluptate ut,
                                    distinctio deleniti excepturi quae ipsum
                                    quidem repellendus vitae laboriosam ducimus
                                    amet repudiandae nemo animi ea reiciendis
                                    autem nesciunt accusantium, quaerat aliquam.
                                    Molestias accusamus voluptas aperiam placeat
                                    laudantium porro rem, voluptatum obcaecati
                                    iusto nisi consequatur incidunt blanditiis.
                                    Quibusdam placeat amet facere itaque
                                    asperiores, consequuntur ipsam, unde
                                    accusamus dicta assumenda enim neque cum
                                    laboriosam, sint ut! Lorem, ipsum dolor sit
                                    amet consectetur adipisicing elit. Sunt
                                    suscipit, eaque, quasi in id laborum
                                    corporis fugiat impedit similique
                                    perferendis aperiam incidunt, sint beatae
                                    inventore qui repudiandae saepe est ex vel
                                    quae assumenda quas nulla dignissimos. Illum
                                    sit, provident dicta quam tenetur vel nam
                                    eum est culpa, nobis qui inventore aperiam
                                    error tempore velit placeat ipsa sapiente
                                    deleniti corporis excepturi. Rerum
                                    accusantium ipsam laudantium, quam numquam
                                    esse cumque a omnis dolorum ad nostrum modi
                                    autem perferendis laboriosam architecto iste
                                    magnam! Perspiciatis dolor voluptas vel quae
                                    vero odio animi ratione repudiandae aut quam
                                    ipsum, placeat quibusdam, fugiat, quaerat
                                    quis. Aliquam nesciunt vitae facilis ut
                                    aspernatur, ducimus a velit, quam nihil id
                                    hic laudantium explicabo sed. Repellendus,
                                    voluptates! At, dolore. Ab illum iste,
                                    doloremque rerum deleniti, incidunt natus
                                    rem veniam commodi magnam praesentium
                                    voluptate nobis at vero aut enim blanditiis.
                                    Animi quia obcaecati inventore ab laudantium
                                    iure perferendis ullam tempora velit
                                    necessitatibus error in eveniet, cumque
                                    expedita cum, quaerat aspernatur debitis
                                    omnis et neque? Iusto qui fugit quibusdam
                                    delectus natus optio officiis
                                    necessitatibus, excepturi iste nisi quis
                                    magni consectetur reprehenderit consequatur
                                    eligendi architecto dignissimos magnam
                                    voluptates inventore impedit. Aperiam eius
                                    animi molestias minima praesentium similique
                                    voluptates tenetur autem omnis inventore
                                    delectus illum enim, quaerat placeat
                                    recusandae officia illo asperiores sequi
                                    nostrum corrupti hic. Esse quod eum magnam
                                    culpa sunt cum tenetur laboriosam
                                    perspiciatis! Hic ut nulla quibusdam
                                    laudantium saepe itaque ullam amet.
                                </Typography>
                            </CardContent>
                        </Card>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default MailDetails;
