import React from "react";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
const AppStarIcon = ({isTrue=false})=>{
    const starStyles = {
        fill: 'gold',  
        width: '24px',  
        height: '24px', 
      };
return(
    
    < > 
    {isTrue?<StarIcon style={starStyles}/>:<StarBorderIcon/>}
    
    </>
)
}

export default AppStarIcon